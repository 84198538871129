import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { AgilityImage } from "@agility/gatsby-image-agilitycms"
import { renderHTML } from "../../agility/agilityUtils"
import { Carousel, ECarouselTitlePosition } from "@organic-return/foundation-react/src/components/Carousel/Carousel"
import {
  Communities,
  ICommunityInfo,
} from "@organic-return/foundation-react/src/components/Blades/Communities"
import TabbedContentBlade, { ITabContent } from "@organic-return/foundation-react/src/components/Blades/TabbedContentBlade"

export interface ICommunityListConfig {
  sortFn: (a: any, b: any) => any;
}

const CommunityList = ({ module }: any) => {
  // graphql query for posts data
  const data = useStaticQuery(graphql`
    query {
      communities: allAgilityCommunity(
        filter: { properties: { referenceName: { eq: "communities" } } }
        sort: {fields: customFields___name, order: ASC}
      ) {
        nodes {
          customFields {
            image {
              url
              height
              width
            }
            name
            bladeImage {
              url
              height
              width
            }
            bladeTitle
            bladeContent
            marketReportBGImage {
              url
            }
          }
          path
          contentID
        }
      }
    }
  `)

  let communitiesList = data.communities.nodes

  if (module.customFields.communitiesToShow && module.customFields.communitiesToShow.length > 0) {
    communitiesList = module.customFields.communitiesToShow.map((community: any) => {
      return data.communities.nodes?.find((item: any) => item.contentID == community.contentID)
    })
  }

  let layout = <></>
  let count = communitiesList?.length ?? 0
  switch (module.customFields.layout) {
    case "carousel-slider":
      layout = (
        <Carousel
          slickSettings={{
            centerMode: true,
            centerPadding: "10px",
            slidesToShow: count >= 3 ? 3 : count,
            arrows: true,
          }}
          className="slick-center-static"
        >
          {communitiesList.map((node: any, index: any) => {
            return (
              <div key={index}>
                <Link
                  to={node.path}
                  title={node.customFields.name}
                  className="inline-block sm:max-w-1/3 text-inherit hover:text-inherit community-slide"
                >
                  {node.customFields.image && (
                    <AgilityImage
                      image={node.customFields.image}
                      width={800}
                      height={600}
                    />
                  )}
                  <div className="p-3">
                    <h2 className="text-center">{node.customFields.name}</h2>
                    {node.customFields.bladeContent && (
                      <div
                        className="my-3 description"
                        dangerouslySetInnerHTML={renderHTML(
                          node.customFields.bladeContent
                        )}
                      ></div>
                    )}
                  </div>
                </Link>
              </div>
            )
          })}
        </Carousel>
      )
      break
    case "carousel-zoom":
      layout = (
        <Carousel
          slickSettings={{
            centerMode: true,
            centerPadding: "10px",
            slidesToShow: count >= 3 ? 3 : count,
            arrows: true,
          }}
          className="slick-center-zoom"
        >
          {communitiesList.map((node: any, index: any) => {
            return (
              <Link
                to={node.path}
                key={index}
                title={node.customFields.name}
                className="inline-block sm:max-w-1/3 text-inherit hover:text-inherit community-slide"
              >
                {node.customFields.image && (
                  <AgilityImage
                    image={node.customFields.image}
                    width={800}
                    height={600}
                  />
                )}
                <div className="p-3">
                  <h2 className="text-center">{node.customFields.name}</h2>
                  {node.customFields.bladeContent && (
                    <div
                      className="my-3 description"
                      dangerouslySetInnerHTML={renderHTML(
                        node.customFields.bladeContent
                      )}
                    ></div>
                  )}
                </div>
              </Link>
            )
          })}
        </Carousel>
      )
      break
    case "carousel-full":
      layout = (
        <Carousel
          titlePosition={ECarouselTitlePosition.overlay}
          title={module.customFields.title}
          className="text-center max-h-screen"
        >
          {communitiesList.map((node: any, index: any) => {
            return (
              <Link
                to={node.path}
                key={index}
                title={node.customFields.name}
                className="relative block w-full text-inherit hover:text-inherit community-slide"
              >
                {node.customFields.image && (
                  <AgilityImage
                    image={node.customFields.image}
                    width={node.customFields.image?.width}
                    layout="fullWidth"
                    className="w-full lg:h-screen lg:max-h-screen"
                    imgClassName="w-full h-full object-cover object-center"
                  />
                )}
                <div className="absolute inset-x-0 bottom-0 p-3 bg-gradient-to-b from-transparent via-black/40 to-black/80">
                  <h2 className="text-center">{node.customFields.name}</h2>
                  {node.customFields.bladeContent && (
                    <div
                      className="my-3 description"
                      dangerouslySetInnerHTML={renderHTML(
                        node.customFields.bladeContent
                      )}
                    ></div>
                  )}
                </div>
              </Link>
            )
          })}
        </Carousel>
      )
      break
    case "blade":
      let info: ICommunityInfo[] = []
      communitiesList.map((node: any, index: any) => {
        let ci: ICommunityInfo = {
          title: node.customFields.name,
          image: {
            url: `${node.customFields.image?.url}`,
            dimensions: [800, 450],
          },
          description: node.customFields.bladeContent,
          links: [
            {
              label: "About",
              url: node.path,
            },
            {
              label: "Our Listings",
              url: `${node.path}#listings&filters=%7B"ours"%3A"on"%7D`,
            },
            {
              label: "All Listings",
              url: `${node.path}#listings`,
            },
          ],
        }
        node.customFields.image?.url &&
          (ci.image = {
            url: `${node.customFields.image?.url}`,
            dimensions: [800, 450],
          })
        info.push(ci)
      })
      layout = (
        <Communities
          title={module.customFields.title}
          communities={info}
          className="w-screen mx-auto"
          displayPagination={module.customFields.displayPagination == "true"}
          scaleImageOnHover={module.customFields.scaleImageOnHover == "true"}
          linkFunc={props => (
            <Link
              to={props.href ?? ""}
              className={props.className}
              key={props.key}
              children={props.children}
            />
          )}
        />
      )
      break
    case "chain-w-cta":
      layout = communitiesList.map((node: any, index: any) => {
        return (
          <div key={index} className={`sm:flex sm:justify-center max-w-screen-xl mx-auto text-inherit hover:text-inherit community-slide ${index % 2 == 0 ? "flex-row-reverse inverted" : ""}`}>
            {node.customFields.image && (
              <div className="sm:w-1/2">
                <AgilityImage
                  image={node.customFields.image}
                  width={800}
                  className="w-ful"
                  height={600}
                />
              </div>
            )}
            <div className="p-3 sm:w-1/2">
              <h2 className="text-center">{node.customFields.name}</h2>
              {node.customFields.bladeContent && (
                <div
                  className="my-3 description"
                  dangerouslySetInnerHTML={renderHTML(
                    node.customFields.bladeContent
                  )}
                ></div>
              )}
              <Link
                to={node.path}
                title={node.customFields.name}
              >
                View More
              </Link>
            </div>
          </div>
        )
      })
      break
    case "tabs":
      let tabContent: ITabContent[] = []
      if (communitiesList && communitiesList.length > 0) {
        tabContent = communitiesList.map((node: any) => {
          let tab: ITabContent = {
            tabTitle: node.customFields.name,
            contentClassName: "w-full",
            content: (

              <div className="relative w-full grid grid-cols-1 grid-rows-1 justify-items-center">
                <AgilityImage
                  image={{
                    url: node.customFields.bladeImage?.url,
                    height: node.customFields.bladeImage?.height,
                    width: node.customFields.bladeImage?.width,
                    label: ""
                  }}
                  formats={["jpg"]}
                  layout="fullWidth"
                  className="object-contain w-full col-start-1 col-end-2 row-start-1 row-end-2"
                />
                <div className="relative w-full h-full max-w-screen-xl col-start-1 col-end-2 row-start-1 row-end-2">
                  <div className="relative xl:top-20 inverted lg:max-w-md text-center p-14 pt-11 m-6">
                    <h3>{node.customFields.bladeTitle}</h3>
                    <div dangerouslySetInnerHTML={{ __html: node.customFields.bladeContent }}></div>
                    <a className="inline-block text-button-text bg-button-bg border mt-9 py-2 px-4" href={`${node.path}`}>View Listings</a>
                  </div>
                </div>
              </div>
            )
          }
          return tab
        });
      }
      layout = (
        <TabbedContentBlade
          className="w-full"
          tabs={tabContent}
          tabListClassName="gap-x-5 lg:gap-x-14"
          tabListItemClassNames={{
            default: "!w-auto md:!w-auto lg:!w-auto",
            active: "!w-auto md:!w-auto lg:!w-auto border-b border-body-bg-inverted"
          }}
        />
      )
      break
    case "chain":
    default:
      layout = communitiesList.map((node: any, index: any) => {
        return (
          <Link
            to={node.path}
            key={index}
            title={node.customFields.name}
            className={`sm:flex max-w-screen-xl mx-auto text-inherit hover:text-inherit community-slide ${index % 2 == 0 ? "flex-row-reverse inverted" : ""
              }`}
          >
            {node.customFields.image && (
              <AgilityImage
                image={node.customFields.image}
                width={800}
                className="sm:w-1/2"
                height={600}
              />
            )}
            <div className="p-3 sm:w-1/2">
              <h2 className="text-center">{node.customFields.name}</h2>
              {node.customFields.bladeContent && (
                <div
                  className="my-3 description"
                  dangerouslySetInnerHTML={renderHTML(
                    node.customFields.bladeContent
                  )}
                ></div>
              )}
            </div>
          </Link>
        )
      })
      break
  }

  return (
    <div className={`community-list ${module.customFields.layout}`}>
      {module.customFields.title && module.customFields.layout != "blade" && module.customFields.layout != "carousel-full" && (
        <h1>{module.customFields.title}</h1>
      )}
      {module.customFields.subheading && module.customFields.layout != "blade" && module.customFields.layout != "carousel-full" && (
        <h4 className="text-center">{module.customFields.subheading}</h4>
      )}
      {layout}
      {module.customFields.displayLink === "true" && module.customFields.link && (
        <Link
          to={module.customFields.link.href}
          title={module.customFields.link.text}
          className="block w-fit mx-auto"
        >
          {module.customFields.link.text}
        </Link>
      )}
    </div>
  )
}

export default CommunityList
